import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { languages } from "./languages";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: "/i18n/{{lng}}.json",
        },
        debug: false,
        fallbackLng: "en",
        whitelist: Object.keys(languages),
        react: {
            bindI18n: "languageChanged loaded",
        },
    });

export { i18n };
