import { useCallback, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { accountService } from "services/account";
import { BROADCAST_MESSAGE } from "broadcastChannel/broadcastChannelManager";
import { useBroadcastChannel } from "hooks/useBroadcastChannel";
import { getAuthenticationContextReference } from "helpers/getAuthenticationContextReference";

export const ProtectedRoute = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { signinRedirect, user } = useAuth();

  const mustChangePinCode = accountService.userMustChangePinCode();
  const redirectToChangePin = mustChangePinCode && "/change-pin" !== pathname;

  const handleChangePinMessage = useCallback(() => {
    navigate("/change-pin");
  }, [navigate]);

  const handleUserLogout = useCallback(async () => {
    const acrValues = getAuthenticationContextReference(
      user?.profile?.idp,
      user?.profile?.locale
    );

    await signinRedirect({...(acrValues && { acr_values: acrValues })});
  }, [signinRedirect, user]);

  /* Subscribe to broadcast channel messages */
  useBroadcastChannel(BROADCAST_MESSAGE.LOGOUT, handleUserLogout);
  useBroadcastChannel(BROADCAST_MESSAGE.CHANGE_PIN, handleChangePinMessage);

  /* Redirect to change pin page if user must change pin code */
  useEffect(() => {
    if (redirectToChangePin) {
      navigate("/change-pin");
    }
  }, [redirectToChangePin, navigate]);

  if (redirectToChangePin) {
    return null;
  }

  return <Outlet />;
};
