export function getAuthenticationContextReference(authm, locale) {
  if (!authm) {
    return null;
  }

  let acr_values = `authm=${authm}`;

  if (locale) {
    acr_values += ` locale=${locale}`; // Space before locale is intentional
  }

  return acr_values;
}
