import { createContext, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

export const BusyStateContext = createContext({
  hasBusyItems: false,
  addBusyItem: (oid) => {
    console.warn("addBusyItem was called without BusyStateContextProvider", oid);
  },
  removeBusyItem: (oid) => {
    console.warn(
      "removeBusyItem was called without BusyStateContextProvider",
      oid
    );
  },
});

export const BusyStateContextProvider = ({ children }) => {
  const [busyItems, setBusyItems] = useState([]);

  const removeBusyItem = useCallback((oid) => {
    setBusyItems((currentItems) =>
      currentItems.filter((val) => val !== oid)
    );
  }, []);

  const addBusyItem = useCallback((oid) => {
    setBusyItems((currentItems) => {
      if (currentItems.includes(oid)) {
        return currentItems;
      }
      return [...currentItems, oid];
    });
  }, []);

  const contextValue = useMemo(() => ({
    hasBusyItems: busyItems.length > 0,
    addBusyItem,
    removeBusyItem,
  }), [busyItems, addBusyItem, removeBusyItem]);

  return (
    <BusyStateContext.Provider value={contextValue}>
      {children}
    </BusyStateContext.Provider>
  );
};

BusyStateContextProvider.propTypes = {
  children: PropTypes.node,
};
